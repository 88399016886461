nav {
  @include small_devices {
    padding: 0 15px;
  }
  ul {
    padding: 0;
    margin-top: 0;
  }
  a {
    color: #474747;
    text-decoration: none;

    li.highlight {
      font-style: italic;
    }
  }
  a.active {
    color: $link-color;
    li {
      font-weight: bold;
      background-color: #ededed;
    }
  }
  li {
    svg {
      height: 24px;
      width: 24px;
      margin-bottom: -5px;
      fill: $link-color;
      margin-right: 25px;
      margin-left: 5px;
    }
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;
    list-style: none;
    display: block;
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    box-shadow: inset 0 -1px 0 0 lightgray;
    padding-left: 10px;
  }
}
