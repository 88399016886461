@font-face {
  font-family: MarkPro;
  src: url('style/fonts/markpro/MarkProLight.otf') format('opentype');
  font-weight: 100; // lighter
  font-style: normal;
}


@font-face {
  font-family: MarkPro;
  src: url('style/fonts/markpro/MarkPro.otf') format('opentype');
  font-weight: normal; // 400
  font-style: normal;
}


@font-face {
  font-family: MarkPro;
  src: url('style/fonts/markpro/MarkProMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: MarkPro;
  src: url('style/fonts/markpro/MarkProBold.otf') format('opentype');
  font-weight: bold; // 700
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, MarkPro, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'style/themes/index';
