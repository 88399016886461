#login {
  .container {
    width: 350px;
    margin: auto;
    padding: 0 20px;
    form {
      width: 320px;
      input {
        width: 100%;
        margin: auto;
      }
      button {
        background-color: $link-color;
        color: white;
        font-weight: 500;
        font-size: 16px;
        font-family: Mark Pro, sans-serif;
        float: right;
        // Compensating for input padding
        margin-right: -10px;
      }
    }
  }

  img {
    padding-top: 20px;
    padding-left: 20px;
    @include desktop_and_tablet {
      padding-top: 40px;
      padding-left: 40px;
    }
  }

  p {
    width: 320px;
  }

  a {
    text-decoration: none;
    font-size: 15px;
    line-height: 40px;
  }

  .error {
    width: 320px;
    background: red;
    color: white;
    padding: 10px;
    font-weight: bolder;
  }
}
