form {
  $min-width: 150px;
  $border-radius: 5px;

  label, input:not([type='radio']), textarea, select {
    display: block;
    border-radius: $border-radius;
  };

  label {
    margin-bottom: 20px;
  };

  input:not([type='radio']) {
    $padding: 5px;
    height: 30px;
    margin-top: 5px;
    min-width: $min-width - $padding - $padding;
    border: solid 1px #d1d1d1;
    padding: $padding;
    width: 96%;
    background-color: $block-color;
  };

  span.error {
    font-size: 0.9em;
    color: red;
    margin: 5px;
  }

  button {
    min-width: $min-width;
    border: none;
  }

  select {
    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    height: 45px;
    width: 100%;
    margin: auto;
    font-size: 16px;
    font-weight: 500;
    background-color: white;
    padding: .6em 1.4em .5em .8em;
    background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
    &.disabled {
      color: grey;
    }
  }
}

