@mixin small_devices {
  @media (max-width: 767px) {
    @content
  }
}

@mixin desktop_and_tablet {
  @media (min-width: 768px) {
    @content
  }
}

@mixin status-background-colors {
  &.active, &.active_with_scheduled_freeze {
    background-color: $active-color;
  }
  &.cancelled, &.cancelled_in_presale, &.cancelled_in_trial, &.stopped {
    background-color: $cancelled-color;
  }
  &.in_cancellation {
    background-color: $in-cancellation-color;
  }
  &.in_freeze {
    background-color: $in-freeze-color;
  }
  &.presale {
    background-color: $presale-color;
  }
  &.in_trial {
    background-color: $in-trial-color;
  }
}

@mixin status-text-colors {
  &.active, &active_with_scheduled_freeze {
    color: $active-color;
  }
  &.cancelled, &.cancelled_in_presale, &.cancelled_in_trial, &.inactive, &.stopped {
    color: $cancelled-color;
  }
  &.in_cancellation, &.suspended {
    color: $in-cancellation-color;
  }
  &.in_freeze {
    color: $in-freeze-color;
  }
  &.presale {
    color: $presale-color;
  }
  &.in_trial {
    color: $in-trial-color;
  }
}
