.theme.evo-hp {
  $primary-color: #01b2aa;
  $link-color: $primary-color;
  $active-color: #0e847e;
  $cancelled-color: #BE3843;
  $in-cancellation-color: #ef7b39;
  $in-freeze-color: #0a607b;

  --primary: #{$primary-color};

  // Apply theme to shared styles
  @import '../../shared/index';
}
