.tooltiptext {
  position: relative;
  visibility: hidden;
  font-size: 0.7em;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
