#profile {
  select {
    margin-top: 5px;
  }
  input {
    background-color: white;
    min-width: 10px;
    width: 100%;
  }
  .form-grid {
    display: grid;
    margin: auto;
    grid-template-areas:
              "address address address"
              "zip divider location"
              "mobile-prefix divider mobile-number"
              "email email email"
              "button button button";
    grid-template-columns: 30% 15% 50%;
  }
  // need to have a \ to be able to use className={'address.street'}
  // ref https://stackoverflow.com/questions/3447329/styling-elements-with-a-dot-in-the-class-name
  .address\.street {
    grid-area: address;
  }
  .address\.postal_code {
    grid-area: zip;
  }
  .address\.postal_location {
    grid-area: location;
  }
  .mobile\.prefix {
    grid-area: mobile-prefix;
  }
  .mobile\.prefix option, .mobile\.prefix select {
    font-size: 0.9em;
  }
  .mobile\.number {
    grid-area: mobile-number;
  }
  .email {
    grid-area: email;
  }
  .divider {
    grid-area: divider;
  }
}
