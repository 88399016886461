.membership {
  .status {
    font-size: 14px;
    text-align: center;
    margin: 10px -20px;
    padding: 10px 20px;
    color: white;
    @include status-background-colors;
    .name {
      line-height: 2.29;
    }
    .membership-info {
      margin-top: 10px;
      margin-bottom: 15px;
      line-height: 1.7;
    }
    button {
      color: white;
      font-weight: bold;
      font-size: 20px;
      background: none;
      width: 80%;
      max-width: 302px;
      margin-bottom: 20px;
    }
    .membership-status {
      line-height: 1.39;
      font-size: 23px;
      font-weight: bold;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  .location-name {
    font-weight: bold;
    font-size: 19px;
    color: $primary-color;
  }

  .chip {
    span {
      @include status-text-colors
    }
  }

  .profile {
    ul {
      padding: 0;
      margin-top: 0;
      li {
        list-style: none;
        line-height: 1.63;
      }
    }
  }

  .cancel {
    width: 100%;
    text-align: center;
    a {
      text-decoration: none;
      color: #eb4365;
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  hr {
    margin: 30px -15px;
    border-top: 1px solid #e4e4e4;
  }
}
