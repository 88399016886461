.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  border: solid 1px $link-color;

  label {
    font-weight: bold;
    text-align: left;
    padding: 5px;
    margin-bottom: 10px;

    .text {
      font-weight: normal;
      font-size: 0.9rem;
      margin: 10px 0;
    }
  }

  input {
    height: 30px;
    border: solid 1px #d1d1d1;
    padding: 5px;
    background-color: $block-color;
  }
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;

  button {
    font-weight: bold;

    &.cancel-button {
      background: #000;
      color: $link-color;
    }

    &.confirm-button {
      background: $link-color;
      color: white;
    }
  }
}
