button {
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid;
  &.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0;
    color: $link-color;
    font-size: 16px;
    &:hover, &:focus {
      text-decoration: none;
      outline: 0;
    }
  }
  &:disabled {
    opacity: 0.7;
  }
  a {
    text-decoration: none;

  }
  &.action-button {
    background-color: $link-color;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 24px;
    font-family: Mark Pro, sans-serif;
    max-width: 50%;
    min-width: 338px;
    height: auto;
    padding: 0.4em;
    margin-top: 10px;
    &.secondary {
      background-color: white;
      color: $link-color;
      border: 1px solid $link-color;
    }
    &.long {
      font-size: 18px;
    }
  }
}

// Hackish utility class to wrap secondary buttons in Link components
a.no-underline {
  text-decoration: none;
}
