#referral {
  .referral-code {
    width: 100%;
    text-align: center;
    padding: 20px;
    .code {
      color: $primary-color;
      font-size: 52px;
      font-weight: bold;
    }
  }
}
