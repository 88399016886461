// Ensure expected variables are initialized...
$primary-color: black !default;
$link-color: $primary-color !default;
$max-main-width: 1024px !default;
$desktop-nav-width: 344px !default;

$block-color: #f0f0f0 !default;


// Status colors
$active-color: #07ac52 !default;
$cancelled-color: #ff0034 !default;
$in-cancellation-color: #FF5D00 !default;
$in-freeze-color: #076ab5 !default;
$presale-color: grey !default;
$in-trial-color: grey !default;

@import 'mixins';
@import 'button';
@import 'top-bar';
@import 'nav';
@import 'forms';
@import 'flex';
@import 'tooltip';
@import 'modal';
@import 'pages/index';


a,.link {
  color: $link-color;
  text-decoration: underline;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 18px;
}

.hidden {
  &.phone {
    @include small_devices {
      display: none
    }
  }
  &.desktop {
    @include desktop_and_tablet {
      display: none
    }
  }
}

.main {
  display: grid;
  max-width: $max-main-width;
  margin: auto;
  @include desktop_and_tablet {
    grid-template-areas:
            "status status"
            "navigation content";
    grid-template-columns:
            $desktop-nav-width
            minmax(auto, $max-main-width - $desktop-nav-width);
  }

  @include small_devices {
    grid-template-columns: 1fr;
    grid-template-areas:
            "status"
            "content"
            "navigation";
  }

  .navigation {
    grid-area: navigation
  }

  .full-width {
    margin: 0 -20px;
    &.block {
      background-color: $block-color;
      padding: 10px 20px;
    }
  }

  .status-bar {
    font-weight: bold;
    color: white;
    grid-area: status;
    padding: 0 20px;
    height: 44px;
    line-height: 44px;
    @include status-background-colors;
    .name {
      @include small_devices {
        display: none;
      }
    }
    .info {
      float: right;
      &:first-letter {
        text-transform: capitalize;
      }
      @include small_devices {
        float: none;
        text-align: center
      }
    }
  }

  .content {
    grid-area: content;
    @include desktop_and_tablet {
      padding: 0 40px;
    }
    @include small_devices {
      padding: 0 20px;
    }

    a {
      font-weight: bold;
    }
  }

  .index-info {
    padding: 10px 20px;
    background-color: #f0f0f0;
    text-align: center;
  }
}

.mobile-link {
  @include desktop_and_tablet {
    display: none;
  }
}

svg {
  height: 24px;
  width: 24px;
  fill: $link-color;
  g.link-color {
    fill: $link-color;
  }
}
