#top-bar {
  height: 53px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  line-height: 53px;
  padding: 0 15px;
  div {
    max-width: $max-main-width;
    margin: auto;
  }
  .sign-out {
    line-height: 53px;
  }
}
