#gdpr_opt_in {
  .gdpr-options {
    margin-bottom: 20px;
    display: grid;
    grid-template-areas: "radio text";
    grid-template-columns: 30px auto;
  }

  .radio {
    margin-top: 5px;
    grid-area: radio;
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
  }

  .text {
    grid-area: text;
  }
}
