#index-info {
  .full-width {
    @include desktop_and_tablet {
      margin: 0 -40px;
    }
  }
  .code, .expiry-label {
    margin-right: auto;
    margin-left: auto;
  }
  .code, .code-inactive {
    color: $link-color;
    font-size: 34px;
    font-weight: bold;
  }
  .code-inactive {
    opacity: 0.7;
    text-decoration: line-through;
    text-decoration-color: grey;
  }
  .expiry-label {
    font-size: 20px;
    font-weight: bold;
    opacity: 0.7;
  }
  .invisible {
    display: none;
  }
  .access-info {
    text-align: left;
    h3 {
      margin-bottom: 0;
    }
    ol {
      margin-block-start: 5px;
      padding-left: 20px;
    }
    li {
      font-size: 18px;
      padding-left: 5px;
      line-height: 1.89;
    }
  }
  .highlight-wrapper {
    a {
      font-weight: normal;
    }
  }
  .highlighted {
    background-color: $link-color;
    color: white;
    padding: 15px 20px;
    min-height: 70px;
    line-height: 1.44;
    .phone {
      position: relative;
      float: left;
      margin-left: 12px;
      margin-right: 20px;
      margin-bottom: 20px;
      width: 35px;
      height: 63px;
      border-radius: 5px;
      background-color: #ffffff;
      img {
        position: absolute;
        top: 30px;
        left: 5px;
        width: 25px;
      }
      .line {
        position: absolute;
        z-index: 9999;
        top: 57px;
        left: 9.5px;
        width: 16px;
        height: 1px;
        background-color: $link-color;
      }
    }
  }
  .workouts {
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 2.13;
    }
    .next {
      display: flex;
      align-items: center;
      color: $link-color;
      cursor: pointer;
      &.disabled {
        color: grey;
      }
    }
    .previous {
      display: flex;
      align-items: center;
      color: $link-color;
      cursor: pointer;
      &.disabled {
        color: grey;
      }
    }
    .number  {
      text-align: center;
      font-size: 122px;
      line-height: 122px;
      font-family: Futura, sans-serif;
      font-weight: bold;
      color: $link-color;
    }
    .month {
      text-align: center;
      height: 40px;
      font-size: 18px;
    }
    .total-this-year {
      background-color: $link-color;
      color: white;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      text-align: center;
      width: 199px;
      padding: 15px;
      margin: auto;
      margin-bottom: 20px;
    }
  }
}

.kter-gjennomfrt-i {
  width: 207px;
  height: 23px;
  opacity: 0.96;
  font-family: MarkPro;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
}

.-kter-totalt-i- {
  width: 154px;
  height: 20px;
  font-family: MarkPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
